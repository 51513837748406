<template lang="pug">
v-combobox(
  :value="value"
  @input="$emit('input', $event)"
  @change="$emit('change', $event)"
  :label="label"
  :items="items"
  :error="error"
  :error-messages="errorMessages"
  timepicker
  :search-input="search"
  @update:search-input="updateSearch"
)
  template(v-slot:append)
    v-icon(:color="value ? 'primary' : ''") {{ icon }}
</template>

<script>
import { DEFAULT_TIME  } from '@/util/const'

export default {
  props: {
    value: {
      required: true
    },
    label: String,
    error: Boolean,
    errorMessages: String,
    icon: {
      type: String,
      default: 'mdi-clock-outline'
    },
  },

  data: () => ({
    items: DEFAULT_TIME,
    search: ''
  }),

  created() {
    this.search = this.value
  },

  methods: {
    updateSearch(val) {
      if(this.search === val) return
      this.search = val
      this.$emit('input', val)
    }
  }
}
</script>